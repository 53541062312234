import React, { memo } from 'react';
import { Layout } from './MobileHeader.style';

function MobileHeader() {
    return (
        <>
            <div>
                <Layout>
                    <h2>雀活マスター会員管理(検証環境)</h2>
                </Layout>
            </div>
        </>
    );
};

export default memo(MobileHeader);
